// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/Quicksand-Bold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@font-face{font-family:\"Quicksand-Bold\";font-style:normal;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}@font-face{font-family:\"Original Yu Gothic\";font-style:normal;font-weight:400;src:local(\"Yu Gothic Bold\")}body{font-family:Quicksand-Bold,\"Hiragino Sans\",\"Hiragino Kaku Gothic ProN\",\"Original Yu Gothic\",sans-serif;margin:0!important}", ""]);
// Exports
module.exports = exports;
