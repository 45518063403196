
























import Vue from 'vue'
import StyledText from '@/components/atoms/StyledText.vue'

export default Vue.extend({
  components: {
    StyledText,
  },
  methods: {
    copyright(): string {
      return `© ${new Date().getFullYear()} Worky inc.`
    },
  },
})
