import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a726d440 = () => interopDefault(import('../pages/createTeam.vue' /* webpackChunkName: "pages/createTeam" */))
const _00024f64 = () => interopDefault(import('../pages/createUser.vue' /* webpackChunkName: "pages/createUser" */))
const _49a7e5a2 = () => interopDefault(import('../pages/download.vue' /* webpackChunkName: "pages/download" */))
const _57717a9f = () => interopDefault(import('../pages/loginApp.vue' /* webpackChunkName: "pages/loginApp" */))
const _3e8765dd = () => interopDefault(import('../pages/t.vue' /* webpackChunkName: "pages/t" */))
const _05765ebb = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/createTeam",
    component: _a726d440,
    name: "createTeam"
  }, {
    path: "/createUser",
    component: _00024f64,
    name: "createUser"
  }, {
    path: "/download",
    component: _49a7e5a2,
    name: "download"
  }, {
    path: "/loginApp",
    component: _57717a9f,
    name: "loginApp"
  }, {
    path: "/t",
    component: _3e8765dd,
    name: "t"
  }, {
    path: "/",
    component: _05765ebb,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
