import { analytics } from '~/plugins/firebase'

export default ({ app }) => {
  app.router.beforeEach(async (to, from, next) => {
    const params = {
      full_path: to.fullPath,
      parsed_path: {
        path: to.path,
        query: to.query,
      },
      type: 'internal', // to distinguish from default page_view event
    }
    console.log('page_view', params)
    analytics.logEvent('page_view', params)
    next()
  })
}
