import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

config.autoAddCss = false

    import  { faApple as fortawesomefreebrandssvgicons_faApple } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faApple)

    import  { faWindows as fortawesomefreebrandssvgicons_faWindows } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faWindows)

    import  { faLinux as fortawesomefreebrandssvgicons_faLinux } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faLinux)

Vue.component('fa', FontAwesomeIcon)
Vue.component('fa-layers', FontAwesomeLayers)
Vue.component('fa-layers-text', FontAwesomeLayersText)
