import { auth } from '~/plugins/firebase'

export default function ({ route, store, redirect }) {
  auth.onAuthStateChanged((user) => {
    if (user) {
      console.log('logged in')
    } else {
      if (route.name != 'index' && route.name != 't') redirect('/')
    }
  })
}
